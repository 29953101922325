<template>
  <b-row>
    <b-col cols="12">
      <h4 v-if="!!resaleAgreement.id">Editando convenio</h4>
      <h4 v-if="!resaleAgreement.id">Creando convenio</h4>
    </b-col>
    <b-col>
      <ValidationObserver
        v-slot="{ invalid }"
        tag="form"
        :disabled="true"
        ref="resalesAgreementsForm"
      >
        <b-form @submit.prevent="addResaleAgreement">
          <b-row>
            <b-col md="3">
              <ValidationProvider name="Precio neto" rules="required|max:13">
                <b-form-group slot-scope="{ errors }" class="">
                  <label>Precio neto a recibir por propietario*</label>
                  <b-input-group prepend="$">
                    <b-form-input
                      @keypress="onlyNumberWithDecimal"
                      type="text"
                      @blur="formatBudget(resaleAgreement, 'netamount')"
                      placeholder="0.00"
                      v-model="resaleAgreement.netamount"
                      :disabled="isSavingResaleAgreement"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider name="Fecha de firma" rules="required">
                <b-form-group slot-scope="{ errors }" class="">
                  <label>Fecha de firma*</label>
                  <flat-pickr
                    :config="resaleDatesForm"
                    :class="`form-control flatpickr-color-resales ${errors.length > 0 ? 'is-invalid' : ''}`"

                    v-model="resaleAgreement.signDate"
                    :disabled="isSavingResaleAgreement"
                    placeholder="Seleccione una fecha"
                    :state="errors.length > 0 ? false : null"
                  />
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider name="Fecha inicio" rules="">
                <b-form-group slot-scope="{ errors }" class="">
                  <label>Fecha inicio</label>
                  <flat-pickr
                    :config="resaleDatesForm"
                    :class="`form-control flatpickr-color-resales ${errors.length > 0 ? 'is-invalid' : ''}`"

                    v-model="resaleAgreement.startDate"
                    :disabled="isSavingResaleAgreement"
                    placeholder="Seleccione una fecha"
                    :state="errors.length > 0 ? false : null"
                  />
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider name="Fecha fin" rules="">
                <b-form-group slot-scope="{ errors }" class="">
                  <label>Fecha fin</label>
                  <flat-pickr
                    :config="resaleEndDatesForm"
                    :class="`form-control flatpickr-color-resales ${errors.length > 0 ? 'is-invalid' : ''}`"

                    v-model="resaleAgreement.endDate"
                    :disabled="isSavingResaleAgreement"
                    placeholder="Seleccione una fecha"
                    :state="errors.length > 0 ? false : null"
                  />
                    <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider name="contrato" rules="size:4000">
                <b-form-group slot-scope="{ errors }" class="">
                  <label>Contrato*</label>
                  <b-form-file
                    v-model="file"
                    :disabled="isSavingResaleAgreement"
                    :class="`form-control flatpickr-color-resales ${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="Escoge un archivo o sueltalo aquí..."
                    drop-placeholder="Suelta el archivo aquí..."
                    :state="errors.length > 0 ? false : null"
                    browse-text="Buscar archivo"
                  />
                  <b-form-invalid-feedback>
                    <!-- {{ errors[0] }} -->
                    El campo contrato debe ser menor a 4MB
                  </b-form-invalid-feedback>

                  <div v-if="!!file" class="mt-1">
                    <p class="m-0">Archivo seleccionado:</p>
                    <!-- <br>  -->
                    <div class="file-name-info">
                      <b-link :href="sourceFile" target="_blank" rel="noopener noreferrer"><strong>{{ file.name }}</strong></b-link>
                      <b-button
                        class="ml-1"
                        size="sm image-delete-button"
                        variant="danger"
                        @click="deleteImage"
                      >X</b-button>
                    </div>
                  </div>
                  <div v-if="!!showFileName" class="mt-1">
                    <p class="m-0">Archivo actual:</p>
                    <!-- <br>  -->
                    <div class="file-name-info">
                      <b-link :href="resaleSuportSourceFile" target="_blank" rel="noopener noreferrer"><strong>{{ showFileName }}</strong></b-link>
                      <b-button
                        class="ml-1"
                        size="sm image-delete-button"
                        variant="danger"
                        @click="deleteImageLocal"
                      >X</b-button>
                    </div>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>


            <!-- <b-col md="3">
              <b-row>
                <b-col md="" v-if="!!showFileName">
                  <b-card-text class="">
                    Archivo actual: <b-link :href="resaleSuportSourceFile" target="_blank" rel="noopener noreferrer"><strong>{{ showFileName }}</strong></b-link>
                  </b-card-text>
                </b-col>
              </b-row>
            </b-col> -->

            <b-col md="3">
              <ValidationProvider rules="" name="Precio de venta">
                <b-form-group slot-scope="{ errors }">
                  <label>Precio de venta al público</label>
                  <b-input-group prepend="$">
                    <b-form-input
                      class="form-control"
                      type="text"
                      @keypress="onlyNumberWithDecimal"
                      @blur="formatBudget(resaleAgreement, 'saleprice')"
                      v-model="resaleAgreement.saleprice"
                      :disabled="isSavingResaleAgreement"
                      placeholder="0.00"
                      :state="errors.length > 0 ? false : null"
                    />
                      <!-- :disabled="contractIsCancelled && selectedContract.endDate" -->
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col md="3">
              <ValidationProvider rules="" name="en lista de precios">
                <b-form-group>
                  <label>Propiedad en lista de precios</label>
                  <b-form-checkbox
                    v-model="resaleAgreement.onpricecatalog"
                    :disabled="isSavingResaleAgreement"
                    switch
                    :value="true"
                    :unchecked-value="false"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- <b-col md="8">
              <ValidationProvider name="Notas" rules="">
                <b-form-group slot-scope="{ errors }" class="m-0">
                <label>Notas</label>
                  <b-form-textarea
                    v-model="resaleAgreement.notes"
                    :disabled="isSavingResaleAgreement"
                    placeholder="Escribe una nota"
                    rows="2"
                    :state="errors.length > 0 ? false : null"
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->

            <b-col cols="12" class="mt-1">
              <div class="float-right d-flex">
                <b-button
                  variant="warning"
                  type="button"
                  class="mr-1"
                  @click="goToResales"
                  :disabled="(isSavingResaleAgreement || isUpdatingContract || isCancellingContract)"
                > Cancelar</b-button>

                <b-button
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="(invalid || isSavingResaleAgreement || isUpdatingContract || isCancellingContract)"
                >
                  <b-spinner small v-if="isSavingResaleAgreement" /> Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-col>

  </b-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import * as moment from 'moment'
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'
import flatPickr from "vue-flatpickr-component";
import { numberWithCommas, getLocalFilePath, showAlertMessage } from "@/helpers/helpers"


export default {
  directives: { can },
	mixins: [utils, acl],
  components: {
    flatPickr,
  },
  props: {
    resaleAgreement: {
      type: Object,
      required: true,
    },
    isUpdatingContract: {
      type: Boolean,
      default: false
    },
    isCancellingContract: {
      type: Boolean,
      default: false
    },
    disableEdition: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      file: null,
      isSavingResaleAgreement: false,
      deleteResaleSupport: false
    }
  },
  computed:{
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubContracts", [ "selectedContract", "contractstatus", "contracts", "requestContracts", ]),
    editableResalePrice(){
      const statusValid = ["PROPUESTA","FIRMADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      const valid = statusValid.includes(this.selectedContract.statuscontractname)
      return !valid
    },
    resaleDatesForm() {
      return {
        minDate: moment(this.selectedContract?.dateStart).format("YYYY-MM-DD"),
        maxDate: moment(this.selectedContract?.endDate || this.selectedContract.dateEnd).format("YYYY-MM-DD"),
        altInput: true,
        altFormat: 'd/m/Y'
      }
    },
    resaleEndDatesForm() {
      return {
        minDate: moment(this.resaleAgreement.startDate || this.selectedContract?.dateStart).format("YYYY-MM-DD"),
        maxDate: moment(this.selectedContract?.endDate || this.selectedContract.dateEnd).format("YYYY-MM-DD"),
        altInput: true,
        altFormat: 'd/m/Y'
      }
    },
    resaleSuportSourceFile(){
      return process.env.VUE_APP_IMG_SRC_API + this.resaleAgreement?.resaleSupport
    },
    sourceFile(){
      if (!!this.file) return getLocalFilePath(this.file)
    },
    showFileName(){
      return this.resaleAgreement?.resaleSupportName || this.resaleAgreement.resaleSupport?.name
    }
  },
  methods: {
    ...mapMutations("fivesClubContracts", ["setOnSaleSelectedContract"]),
    ...mapActions("fivesClubContracts", ["setOnSaleSelectedContract", "saveResaleAgreements", "updateResaleAgreements"]),
    formatBudget(item, data){
      if (item[data]) item[data] = numberWithCommas(item[data]);
    },
    goToResales(){
      this.$emit('return-to-list')
    },
    async addResaleAgreement(){
      this.isSavingResaleAgreement = true

      const payload = {
        id: this.resaleAgreement.id || null,
        idContract: this.selectedContract.id,
        idUser: this.user.idUser,

        resaleSupport: this.resaleAgreement.resaleSupport,
        resaleSupportName: this.resaleAgreement.resaleSupportName,
        signDate: !!this.resaleAgreement.signDate ? this.resaleAgreement.signDate : null,
        startDate: !!this.resaleAgreement.startDate ? this.resaleAgreement.startDate : null,
        endDate: !!this.resaleAgreement.endDate ? this.resaleAgreement.endDate : null,
        netamount: !!this.resaleAgreement.netamount ? this.resaleAgreement.netamount : null,
        onpricecatalog: this.resaleAgreement.onpricecatalog,
        saleprice: !!this.resaleAgreement.saleprice ? this.resaleAgreement.saleprice : null,
        notes: this.resaleAgreement.notes,
        deleteResaleSupport: this.deleteResaleSupport
      }

      if (typeof payload?.netamount == 'string') payload.netamount = this.resaleAgreement?.netamount?.split(",").join("")
      if (typeof payload?.saleprice == 'string') payload.saleprice = this.resaleAgreement?.saleprice?.split(",").join("")

      if (!!this.file) {
        payload.resaleSupport = this.file
        payload.resaleSupportName = this.file.name.replace(/\.[^\/.]+$/, '')
      }

      let response

      if (!!this.resaleAgreement.id) response = await this.updateResaleAgreements({...payload})
      else response = await this.saveResaleAgreements({...payload})

      if (response) {
        if (!!this.resaleAgreement.id) showAlertMessage("Convenio actualizado","BellIcon","Se han actualizado los datos","success",4000,"bottom-right")
        else showAlertMessage("Convenio guardado","BellIcon","Se ha guardado el nuevo convenio","success",4000,"bottom-right")

        this.$emit('reload-agreements')
        this.$emit('return-to-list')
      }
      this.isSavingResaleAgreement = false
    },
    deleteImage(){
      this.file = null
    },
    deleteImageLocal(){
      this.resaleAgreement.resaleSupportName = null
      this.resaleAgreement.resaleSupport = null
      this.deleteResaleSupport = true
    },
  }
}
</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-flatpicker.scss";

  .flatpickr-color-resales{
    background-color: white !important;
  }
  .flatpickr-color-resales:disabled{
    background-color: #efefef !important;
  }
  .image-delete-button{
    padding: 0.4rem 0.6rem;
    font-size: smaller;
  }
  .file-name-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
    font-size: smaller;
  }
</style>