var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(!!_vm.resaleAgreement.id)?_c('h4',[_vm._v("Editando convenio")]):_vm._e(),(!_vm.resaleAgreement.id)?_c('h4',[_vm._v("Creando convenio")]):_vm._e()]),_c('b-col',[_c('ValidationObserver',{ref:"resalesAgreementsForm",attrs:{"tag":"form","disabled":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addResaleAgreement($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Precio neto","rules":"required|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Precio neto a recibir por propietario*")]),_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{attrs:{"type":"text","placeholder":"0.00","disabled":_vm.isSavingResaleAgreement,"state":errors.length > 0 ? false : null},on:{"keypress":_vm.onlyNumberWithDecimal,"blur":function($event){return _vm.formatBudget(_vm.resaleAgreement, 'netamount')}},model:{value:(_vm.resaleAgreement.netamount),callback:function ($$v) {_vm.$set(_vm.resaleAgreement, "netamount", $$v)},expression:"resaleAgreement.netamount"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Fecha de firma","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Fecha de firma*")]),_c('flat-pickr',{class:("form-control flatpickr-color-resales " + (errors.length > 0 ? 'is-invalid' : '')),attrs:{"config":_vm.resaleDatesForm,"disabled":_vm.isSavingResaleAgreement,"placeholder":"Seleccione una fecha","state":errors.length > 0 ? false : null},model:{value:(_vm.resaleAgreement.signDate),callback:function ($$v) {_vm.$set(_vm.resaleAgreement, "signDate", $$v)},expression:"resaleAgreement.signDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Fecha inicio","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Fecha inicio")]),_c('flat-pickr',{class:("form-control flatpickr-color-resales " + (errors.length > 0 ? 'is-invalid' : '')),attrs:{"config":_vm.resaleDatesForm,"disabled":_vm.isSavingResaleAgreement,"placeholder":"Seleccione una fecha","state":errors.length > 0 ? false : null},model:{value:(_vm.resaleAgreement.startDate),callback:function ($$v) {_vm.$set(_vm.resaleAgreement, "startDate", $$v)},expression:"resaleAgreement.startDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Fecha fin","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Fecha fin")]),_c('flat-pickr',{class:("form-control flatpickr-color-resales " + (errors.length > 0 ? 'is-invalid' : '')),attrs:{"config":_vm.resaleEndDatesForm,"disabled":_vm.isSavingResaleAgreement,"placeholder":"Seleccione una fecha","state":errors.length > 0 ? false : null},model:{value:(_vm.resaleAgreement.endDate),callback:function ($$v) {_vm.$set(_vm.resaleAgreement, "endDate", $$v)},expression:"resaleAgreement.endDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"name":"contrato","rules":"size:4000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Contrato*")]),_c('b-form-file',{class:("form-control flatpickr-color-resales " + (errors.length > 0 ? 'is-invalid' : '')),attrs:{"disabled":_vm.isSavingResaleAgreement,"placeholder":"Escoge un archivo o sueltalo aquí...","drop-placeholder":"Suelta el archivo aquí...","state":errors.length > 0 ? false : null,"browse-text":"Buscar archivo"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('b-form-invalid-feedback',[_vm._v(" El campo contrato debe ser menor a 4MB ")]),(!!_vm.file)?_c('div',{staticClass:"mt-1"},[_c('p',{staticClass:"m-0"},[_vm._v("Archivo seleccionado:")]),_c('div',{staticClass:"file-name-info"},[_c('b-link',{attrs:{"href":_vm.sourceFile,"target":"_blank","rel":"noopener noreferrer"}},[_c('strong',[_vm._v(_vm._s(_vm.file.name))])]),_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm image-delete-button","variant":"danger"},on:{"click":_vm.deleteImage}},[_vm._v("X")])],1)]):_vm._e(),(!!_vm.showFileName)?_c('div',{staticClass:"mt-1"},[_c('p',{staticClass:"m-0"},[_vm._v("Archivo actual:")]),_c('div',{staticClass:"file-name-info"},[_c('b-link',{attrs:{"href":_vm.resaleSuportSourceFile,"target":"_blank","rel":"noopener noreferrer"}},[_c('strong',[_vm._v(_vm._s(_vm.showFileName))])]),_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm image-delete-button","variant":"danger"},on:{"click":_vm.deleteImageLocal}},[_vm._v("X")])],1)]):_vm._e()],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"Precio de venta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Precio de venta al público")]),_c('b-input-group',{attrs:{"prepend":"$"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"text","disabled":_vm.isSavingResaleAgreement,"placeholder":"0.00","state":errors.length > 0 ? false : null},on:{"keypress":_vm.onlyNumberWithDecimal,"blur":function($event){return _vm.formatBudget(_vm.resaleAgreement, 'saleprice')}},model:{value:(_vm.resaleAgreement.saleprice),callback:function ($$v) {_vm.$set(_vm.resaleAgreement, "saleprice", $$v)},expression:"resaleAgreement.saleprice"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"","name":"en lista de precios"}},[_c('b-form-group',[_c('label',[_vm._v("Propiedad en lista de precios")]),_c('b-form-checkbox',{attrs:{"disabled":_vm.isSavingResaleAgreement,"switch":"","value":true,"unchecked-value":false},model:{value:(_vm.resaleAgreement.onpricecatalog),callback:function ($$v) {_vm.$set(_vm.resaleAgreement, "onpricecatalog", $$v)},expression:"resaleAgreement.onpricecatalog"}})],1)],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right d-flex"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","type":"button","disabled":(_vm.isSavingResaleAgreement || _vm.isUpdatingContract || _vm.isCancellingContract)},on:{"click":_vm.goToResales}},[_vm._v(" Cancelar")]),_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":(invalid || _vm.isSavingResaleAgreement || _vm.isUpdatingContract || _vm.isCancellingContract)}},[(_vm.isSavingResaleAgreement)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Guardar ")],1)],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }