<template>
  <b-row>
    <b-col cols="12" class=""><h4>Convenios de reventa</h4></b-col>
    <b-col v-if="!disableEdition && can('fivesclub_contracts_show_contracts_resales_show_add_button')">
      <b-button
        type="button"
        class="float-right"
        variant="primary"
        @click="addNewResale"
      > <feather-icon icon="PlusIcon" size="12" />
      </b-button>
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-table
        v-if="resales.length > 0"
        :items="resales"
        :fields="fields"
        responsive
        small
        sticky-header="400px"
        sort-by="signDate"
        sort-desc
        class="resale-table-font-size"
        :busy.sync="isDeletingResaleAgreement"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner> <br>
            <strong>Cargando registros de convenios de reventa</strong>
          </div>
        </template>

        <template #head()="data">
          <div class="text-center">{{data.label}}</div>
        </template>

        <template #head(netamount)="">
          <div class="text-center custom-width-resales">Precio neto a recibir <br> por propietario</div>
        </template>

        <template #head(saleprice)="">
          <div class="text-center custom-width-resales">Precio de venta <br> al público</div>
        </template>

        <template #cell(netamount)="data">
          <div class="text-right">{{data.value}}</div>
        </template>

        <template #cell(saleprice)="data">
          <div class="text-right">{{data.value}}</div>
        </template>

        <template #cell(resaleSupport)="row">
          <b-button
            v-if="!!row.item.resaleSupport"
            :href="imgUrl + row.item.resaleSupport"
            target="_blank"
            rel="noopener noreferrer"
            v-b-tooltip.hover.left
            size="sm"
            variant="link"
            :title="row.item.resaleSupportName"
          >
            <feather-icon icon="FileTextIcon" size="1.5x" />
          </b-button>
          <span v-else>--</span>
        </template>

        <template #cell(actions)="row">
          <div class="d-flex justify-content-center">
            <b-button variant="primary" class="mr-1" @click.prevent="editResale(row.item.id)" size="sm" v-if="!disableEdition && can('fivesclub_contracts_show_contracts_resales_show_edit_button')">
              <feather-icon icon="Edit2Icon" size="12" />
            </b-button>

            <b-button variant="danger" @click.prevent="deleteResale(row.item.id)" size="sm" v-if="!disableEdition && can('fivesclub_contracts_show_contracts_resales_show_delete_button')">
              <feather-icon icon="TrashIcon" size="12" />
            </b-button>
          </div>
        </template>
      </b-table>

      <div v-else-if="isLoadingResaleAgreements" class="text-center">
        <b-spinner /> <br> Cargando convenios de reventa
      </div>

      <div v-else>
        <b-alert show variant="warning" class="p-1 text-center">No hay Convenios de reventa añadidos.</b-alert>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { toCurrency, formatDateSpecific } from "@/helpers/helpers"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'

export default {
  directives: { can },
  mixins: [utils, acl],
  props: {
    resales: {
      type: Array,
      required: true,
      default: []
    },
    isLoadingResaleAgreements: {
      type: Boolean,
      required: true
    },
    disableEdition: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      fields: [
        { key:'signDate', label: 'Fecha de firma', class: 'text-center custom-padding-resales font-weight-bolder text-nowrap', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        }},
        { key:'startDate', label: 'Inicio', class: 'text-center custom-padding-resales text-nowrap', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        }},
        { key:'endDate', label: 'Fin', class: 'text-center custom-padding-resales text-nowrap', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        }},
        { key:'netamount', label: 'Precio neto', class: 'text-center custom-padding-resales custom-width-resales font-weight-bolder', formatter: value => {
          return !!value ? this.formatCurrency(value) : '--'
        }},
        { key:'saleprice', label: 'Precio de venta', class: 'text-center custom-padding-resales custom-width-resales font-weight-bolder', formatter: value => {
          return !!value ? this.formatCurrency(value) : '--'
        }},
        { key:'onpricecatalog', label: 'Publicado', class: 'custom-padding-resales text-center', formatter: value => {
          return value ? 'Si' : 'No'
        }},
        { key:'resaleSupport', label: 'Contrato', class: 'custom-padding-resales text-center' },
        { key:'actions', label: 'Acciones', class: 'custom-padding-resales text-center' },
        // { key:'notes', label: 'Notas', class: 'w-50' },
      ],
      isDeletingResaleAgreement: false
    }
  },
  methods: {
    ...mapActions("fivesClubContracts", ["deleteResaleAgreements"]),
    addNewResale(){
      this.$emit('add-new-resale')
    },
    editResale(id){
      this.$emit('edit-resale', id)
    },
    formatCurrency(amount){
      return toCurrency(parseFloat(amount))
    },
    formatThisDate(date){
      return formatDateSpecific({date, toFormat: 'DD/MM/YYYY'})
    },
    async deleteResale(id){
      const payloadAction = { title: '¿Está seguro de borrar este registro?', text: 'Esta acción no se puede deshacer', showDenyButton: true, confirmButtonText: 'Sí, borrar', denyButtonText: 'Cancelar'}
      const { isConfirmed } = await this.$swal.fire(payloadAction)
      if( isConfirmed ){
        this.isDeletingResaleAgreement = true
        const response = await this.deleteResaleAgreements({id})
        if (response) this.$emit('reload-agreements')
        this.isDeletingResaleAgreement = false
      }
    }
  }
}
</script>

<style>
.resale-table-font-size{
  font-size: 0.9rem;
}
.custom-padding-resales{
  padding: 0.6rem !important;
}
.custom-width-resales{
  width: 11.8rem;
}
</style>