<template>
  <div>
    <b-row class="border rounded p-1">
      <b-col>
        <RemodelationsList
          v-if="!showForm"
          :remodelations="selectedContract.currentRemodelations"
          :isLoadingRemodelationAgreements="isLoadingRemodelations"
          :disableEdition="false"
          @add-new-remodelation="createAgreeemnt"
          @edit-remodelation="editRemodelation"
          @reload-remodelations="getContractRemodelations"
        />
        <RemodelationsForm
          v-if="showForm && canSeeForm"
          :remodelationAgreement="remodelationData"
          :showForm="showForm"
          :disableEdition="false"
          @return-to-list="disableForm"
          @reload-remodelations="getContractRemodelations"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState} from 'vuex'
import { showAlertMessage, numberWithCommas } from '@/helpers/helpers'
import { utils } from '@/modules/fivesClub/mixins/utils'
import RemodelationsList from "@/modules/fivesClub/components/contracts/remodelations/RemodelationsList"
import RemodelationsForm from "@/modules/fivesClub/components/contracts/remodelations/RemodelationsForm"

import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'

export default {
  directives: { can },
  mixins: [utils, acl],
	components: {
    RemodelationsList,
    RemodelationsForm
  },
  async created() {
    if (this.remodelationTypes.length == 0) await this.fetchContractsRemodelationType()
  },
  props: {
    contractIsCancelled: {
      type: Boolean,
      required: true,
      default: true
    },
    tabIndex: {
      type: Number,
      required: true
    },
    firstSearch: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      remodelationData: {
        agreementDate: null,
        agreementType: null,
        year: null,
        remodelated: false,
        remodelationDate: null,
        amountBudget: null,
        remodelationReport: null,
        remodelationReportName: null,
      },
      showForm: false,
      isLoadingRemodelations: false,
      isDeletingRemodelations: false,
    }
  },
  watch: {
    tabIndex: {
			deep: true,
			async handler() {
        if (this.tabIndex == 2){
          if (this.selectedContract.currentRemodelations.length == 0 && !this.firstSearch) {
            await this.getContractRemodelations()
            this.$emit('set-first-search', 'firstSearchRemodelations')
          }
        }
			},
		}
  },
  computed:{
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubContracts", [ "selectedContract", "contractstatus", "contracts", "requestContracts", "remodelationTypes"]),

    canSeeForm(){
      return this.can('fivesclub_contracts_show_contracts_action_button_remodelation_add_button') || this.can('fivesclub_contracts_show_contracts_remodelations_show_edit_button')
    }
  },
  methods: {
    ...mapMutations('fivesClubContracts', ['setCurrentRemodelation']),
    ...mapActions('fivesClubContracts', ['fetchRemodelationsPerContract', 'fetchContractsRemodelationType']),
    async getContractRemodelations(){
      this.isLoadingRemodelations = true
      const currentRemodelations = await this.fetchRemodelationsPerContract({IdContract: this.selectedContract.id})
      this.setCurrentRemodelation(currentRemodelations)
      this.isLoadingRemodelations = false
    },
    createAgreeemnt(){
      this.showForm = true
    },
    async editRemodelation(id){
      const agreement = await this.fetchRemodelationsPerContract({IdAgreement: id})
      if (!!agreement) {
        if (!!agreement?.amountBudget) agreement.amountBudget = numberWithCommas(agreement?.amountBudget)
        this.remodelationData = agreement
        this.showForm = true
      } else showAlertMessage('No se pudo cargar registro', 'infoIcon', 'No se pudo cargar este registro, por favor inténtelo más tarde', 'warning', 4000)
    },
    disableForm(){
      this.showForm = false
      this.remodelationData = {
        agreementDate: null,
        agreementType: null,
        year: null,
        remodelated: false,
        remodelationDate: null,
        amountBudget: null,
        remodelationReport: null,
        remodelationReportName: null
      }
    }
  }
}
</script>