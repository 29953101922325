<template>
  <div>
    <b-row class="border rounded p-1">
      <!-- <b-col md="12">
        <ValidationProvider rules="" name="en venta">
          <b-form-group>
            <label>Propiedad en Venta</label>
            <b-form-checkbox
              v-model="selectedContract.isResale"
              switch
              @change="setOnSaleSelectedContract(selectedContract.isResale)"
            />
          </b-form-group>
        </ValidationProvider>
      </b-col> -->

      <b-col>
        <ResalesList
          v-if="!showForm"
          :resales="selectedContract.resaleAgreements"
          :isLoadingResaleAgreements="isLoadingResaleAgreements"
          :disableEdition="false"
          @add-new-resale="createAgreeemnt"
          @edit-resale="editAgreeemnt"
          @reload-agreements="getResaleAgreements"
        />
        <ResalesForm
          v-if="showForm && canSeeForm"
          :resaleAgreement="resaleAgreementData"
          :showForm="showForm"
          :disableEdition="false"
          @return-to-list="disableForm"
          @reload-agreements="getResaleAgreements"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'
import ResalesList from "@/modules/fivesClub/components/contracts/resales/ResalesList"
import ResalesForm from "@/modules/fivesClub/components/contracts/resales/ResalesForm"

import { numberWithCommas, showAlertMessage } from "@/helpers/helpers"

export default {
  directives: { can },
	mixins: [utils, acl],
  components: {
    ResalesList,
    ResalesForm
  },
  props: {
    contractIsCancelled: {
      type: Boolean,
      required: true,
      default: true
    },
    tabIndex: {
      type: Number,
      required: true
    },
    firstSearch: {
      type: Boolean,
      required: true,
    },
  },
  data(){
    return {
      showForm: false,
      isLoadingResaleAgreements: false,
      resaleAgreementData: {
        resaleSupport: null,
        resaleSupportName: null,
        signDate: null,
        startDate: null,
        endDate: null,
        netamount: null,
        onpricecatalog: false,
        saleprice: null,
        notes: null,
      },
    }
  },
  watch: {
    tabIndex: {
			deep: true,
			async handler() {
        if (this.tabIndex == 1 && this.selectedContract.resaleAgreements.length == 0 && !this.firstSearch) {
          await this.getResaleAgreements()
          this.$emit('set-first-search', 'firstSearchResales')
        }
			},
		}
  },
  computed:{
    ...mapState("fivesClubContracts", [ "selectedContract", "contractstatus", "contracts", "requestContracts", ]),
    canSeeForm(){
      return this.can('fivesclub_contracts_show_contracts_resales_show_add_button') || this.can('fivesclub_contracts_show_contracts_resales_show_edit_button')
    }
  },
  methods: {
    ...mapMutations("fivesClubContracts", ["setOnSaleSelectedContract", "setResaleAgreements"]),
    ...mapActions("fivesClubContracts", ["fetchResaleAgreements"]),

    formatBudget(item, data){
      if (item[data]) item[data] = numberWithCommas(item[data]);
    },
    createAgreeemnt(){
      this.showForm = true
    },
    async editAgreeemnt(id){
      const agreement = await this.fetchResaleAgreements({IdAgreement: id})
      if (!!agreement) {
        if (!!agreement?.netamount) agreement.netamount = numberWithCommas(agreement?.netamount)
        if (!!agreement?.saleprice) agreement.saleprice = numberWithCommas(agreement?.saleprice)
        this.resaleAgreementData = agreement
        this.showForm = true
      } else showAlertMessage('No se pudo cargar registro', 'infoIcon', 'No se pudo cargar este registro, por favor inténtelo más tarde', 'warning', 4000)
    },
    disableForm(){
      this.showForm = false
      this.resaleAgreementData = {
        resaleSupport: null,
        resaleSupportName: null,
        signDate: null,
        startDate: null,
        endDate: null,
        netamount: null,
        onpricecatalog: false,
        saleprice: null,
        notes: null,
      }
    },
    async getResaleAgreements(){
      this.isLoadingResaleAgreements = true
      const resaleAgreements = await this.fetchResaleAgreements({IdContract: this.selectedContract.id})
      this.setResaleAgreements(resaleAgreements)
      this.isLoadingResaleAgreements = false
    }
  }
}
</script>