<template>
  <b-row>
    <b-col cols="12">
      <h4 v-if="!!remodelationAgreement.id">Editando convenio</h4>
      <h4 v-if="!remodelationAgreement.id">Creando convenio</h4>
    </b-col>
    <b-col>
      <ValidationObserver
        v-slot="{ invalid }"
        tag="form"
        :disabled="true"
        ref="resalesAgreementsForm"
      >
        <b-form @submit.prevent="saveRemodelationAgreement">
          <b-row>
            <b-col md="9">
              <b-row>
                <b-col md="4">
                  <ValidationProvider name="Fecha de convenio" rules="required">
                    <b-form-group slot-scope="{ errors }" class="">
                      <label>Fecha de convenio*</label>
                      <flat-pickr
                        :config="remodelationsDatesForm"
                        :class="`form-control flatpickr-color-remodelation ${errors.length > 0 ? 'is-invalid' : ''}`"

                        v-model="remodelationAgreement.agreementDate"
                        :disabled="isSavingResaleAgreement"
                        placeholder="Seleccione una fecha"
                        :state="errors.length > 0 ? false : null"
                      />
                        <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <ValidationProvider name="Tipo de convenio" rules="required">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Tipo de convenio*</label>
                      <b-form-select
                        :state="errors[0] ? false : valid ? true : null"
                        v-model="remodelationAgreement.agreementType"
                        :disabled="isSavingResaleAgreement"
                      >
                        <option :value="null" disabled>Seleccione tipo de convenio</option>
                        <option
                          v-for="agreementType in remodelationTypes"
                          :key="agreementType.id"
                          :value="agreementType.id"
                        >
                          {{ agreementType.name }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <ValidationProvider name="Año de compromiso" rules="">
                    <b-form-group slot-scope="{ errors }">
                      <label>Año de compromiso</label>
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="remodelationAgreement.year"
                        :disabled="isSavingResaleAgreement"
                      >
                        <option :value="null">Seleccione año de compromiso</option>
                        <option
                          v-for="year in contractYears"
                          :key="year"
                          :value="year"
                        >
                          {{ year }}
                        </option>
                      </b-form-select>
                      <b-form-invalid-feedback>{{errors[0]}}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4">
                  <ValidationProvider rules="" name="remodelación realizada">
                    <b-form-group>
                      <label>Remodelación realizada</label>
                      <b-form-checkbox
                        v-model="remodelationAgreement.remodelated"
                        :disabled="isSavingResaleAgreement"
                        switch
                        :value="true"
                        :unchecked-value="false"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4" v-if="remodelationAgreement.remodelated" >
                  <ValidationProvider name="Fecha de remodelación" rules="">
                    <b-form-group slot-scope="{ errors }" class="">
                      <label>Fecha de remodelación</label>
                      <flat-pickr
                        :config="remodelationsDatesForm"
                        :class="`form-control flatpickr-color-remodelation ${errors.length > 0 ? 'is-invalid' : ''}`"

                        v-model="remodelationAgreement.remodelationDate"
                        :disabled="isSavingResaleAgreement"
                        placeholder="Seleccione una fecha"
                        :state="errors.length > 0 ? false : null"
                      />
                        <!-- :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" -->
                      <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col md="4" v-if="remodelationAgreement.remodelated" >
                  <ValidationProvider rules="required" name="Costo de remodelación">
                    <b-form-group slot-scope="{ valid, errors }">
                      <label>Costo de remodelación</label>
                      <b-input-group prepend="$">
                        <b-form-input
                          class="form-control"
                          type="text"
                          @keypress="onlyNumberWithDecimal"
                          @blur="formatBudget(remodelationAgreement, 'amountBudget')"
                          v-model="remodelationAgreement.amountBudget"
                          :disabled="isSavingResaleAgreement"
                          placeholder="0.00"
                          :state="errors[0] ? false : valid ? true : null"
                        />
                          <!-- :disabled="contractIsCancelled && selectedContract.endDate" -->
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-input-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="3">
              <ValidationProvider name="contrato" rules="size:4000">
                <b-form-group slot-scope="{ errors }" class="">
                  <label>Contrato</label>
                  <b-form-file
                    v-model="file"
                    :disabled="isSavingResaleAgreement"
                    placeholder="Escoge un archivo o sueltalo aquí..."
                    drop-placeholder="Suelta el archivo aquí..."
                    :state="errors.length > 0 ? false : null"
                    browse-text="Buscar archivo"
                  />
                  <b-form-invalid-feedback>
                    <!-- {{ errors[0] }} -->
                    El campo contrato debe ser menor a 4MB
                  </b-form-invalid-feedback>

                  <div v-if="!!file" class="mt-1">
                    <p class="m-0">Archivo seleccionado:</p>
                    <!-- <br>  -->
                    <div class="file-name-info">
                      <b-link :href="sourceFile" target="_blank" rel="noopener noreferrer"><strong>{{ file.name }}</strong></b-link>
                      <b-button
                        class="ml-1"
                        size="sm image-delete-button"
                        variant="danger"
                        @click="deleteImage"
                      >X</b-button>
                    </div>
                  </div>
                  <div v-if="!!showFileName" class="mt-1">
                    <p class="m-0">Archivo actual:</p>
                    <!-- <br>  -->
                    <div class="file-name-info">
                      <b-link :href="resaleSuportSourceFile" target="_blank" rel="noopener noreferrer"><strong>{{ showFileName }}</strong></b-link>
                      <b-button
                        class="ml-1"
                        size="sm image-delete-button"
                        variant="danger"
                        @click="deleteImageLocal"
                      >X</b-button>
                    </div>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- <b-col md="8">
              <ValidationProvider name="Notas" rules="">
                <b-form-group slot-scope="{ errors }" class="m-0">
                <label>Notas</label>
                  <b-form-textarea
                    v-model="remodelationAgreement.notes"
                    :disabled="isSavingResaleAgreement"
                    placeholder="Escribe una nota"
                    rows="2"
                    :state="errors.length > 0 ? false : null"
                  >
                  </b-form-textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col> -->

            <b-col cols="12" class="mt-1">
              <div class="float-right d-flex">
                <b-button
                  variant="warning"
                  type="button"
                  class="mr-1"
                  @click="goToResales"
                  :disabled="(isSavingResaleAgreement || isUpdatingContract || isCancellingContract)"
                > Cancelar</b-button>

                <b-button
                  type="submit"
                  class="btn-block"
                  variant="primary"
                  :disabled="(invalid || isSavingResaleAgreement || isUpdatingContract || isCancellingContract)"
                >
                  <b-spinner small v-if="isSavingResaleAgreement" /> Guardar
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-col>

  </b-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import * as moment from 'moment'
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'
import flatPickr from "vue-flatpickr-component";
import { numberWithCommas, getLocalFilePath, showAlertMessage } from "@/helpers/helpers"


export default {
  directives: { can },
	mixins: [utils, acl],
  components: {
    flatPickr,
  },
  props: {
    remodelationAgreement: {
      type: Object,
      required: true,
    },
    isUpdatingContract: {
      type: Boolean,
      default: false
    },
    isCancellingContract: {
      type: Boolean,
      default: false
    },
    disableEdition: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      file: null,
      isSavingResaleAgreement: false,
      deleteRemodelationReport: false,
    }
  },
  computed:{
    ...mapState("auth", ["user"]),
    ...mapState("fivesClubContracts", [ "selectedContract", "contractstatus", "contracts", "requestContracts", "remodelationTypes"]),
    editableResalePrice(){
      const statusValid = ["PROPUESTA","FIRMADO"] // borrador-> 1, propuesta -> 3, cancelado ->  4
      const valid = statusValid.includes(this.selectedContract.statuscontractname)
      return !valid
    },
    remodelationsDatesForm() {
      return {
        altInput: true,
        altFormat: 'd/m/Y'
      }
    },
    resaleSuportSourceFile(){
      return process.env.VUE_APP_IMG_SRC_API + this.remodelationAgreement?.remodelationReport
    },
    sourceFile(){
      if (!!this.file) return getLocalFilePath(this.file)
    },
    showFileName(){
      return this.remodelationAgreement?.remodelationReportName || this.remodelationAgreement.remodelationReport?.name
    },
    contractYears(){
      const minDate = moment(this.selectedContract?.dateStart).format("YYYY")
      const diference = parseInt(this.selectedContract.duration)
      const years = []

      for (let idx = 0; idx <= diference; idx++) {
        years.push(parseInt(minDate) + idx)
      }
      return years
    }
  },
  methods: {
    ...mapActions("fivesClubContracts", ["saveRemodelationPerContract", "updateRemodelationPerContract"]),
    formatBudget(item, data){
      if (item[data]) item[data] = numberWithCommas(item[data]);
    },
    goToResales(){
      this.$emit('return-to-list')
    },
    async saveRemodelationAgreement(){
      this.isSavingResaleAgreement = true
      let response = false

      const payload = {
        idContract: this.selectedContract.id,
        id: this.remodelationAgreement.id || null,

        agreementDate: !!this.remodelationAgreement.agreementDate ? this.remodelationAgreement.agreementDate : null,
        agreementType: this.remodelationAgreement.agreementType,
        year: this.remodelationAgreement.year,
        remodelated: this.remodelationAgreement.remodelated,
        remodelationDate: (!!this.remodelationAgreement.remodelationDate && this.remodelationAgreement.remodelated) ? this.remodelationAgreement.remodelationDate : null,
        amountBudget: this.remodelationAgreement.remodelated && !!this.remodelationAgreement.amountBudget ? this.remodelationAgreement.amountBudget : null,
        // notes: this.remodelationAgreement.notes,
        deleteRemodelationReport: this.deleteRemodelationReport,

        remodelationReport: this.remodelationAgreement.remodelationReport,
        remodelationReportName: this.remodelationAgreement.remodelationReportName,

        idUser: this.user.idUser
      }

      if (typeof payload?.amountBudget == 'string') payload.amountBudget = this.remodelationAgreement?.amountBudget?.split(",").join("")

      if (!!this.file) {
        payload.remodelationReport = this.file
        payload.remodelationReportName = this.file.name.replace(/\.[^\/.]+$/, '')
      }

      if (!!this.remodelationAgreement.id) response = await this.updateRemodelationPerContract(payload)
      else response = await this.saveRemodelationPerContract(payload)

      if (response) {
        if (!!this.remodelationAgreement.id) showAlertMessage("Convenio actualizado","BellIcon","Se han actualizado los datos","success",4000,"bottom-right")
        else showAlertMessage("Convenio guardado","BellIcon","Se ha guardado el nuevo convenio","success",4000,"bottom-right")

        this.$emit('reload-remodelations')
        this.$emit('return-to-list')
      } else if (!response) {
        showAlertMessage( "Remodelations not Saved", "BellIcon", "We have an issue saving your registers, please try again later", "warning", 4000, "bottom-right" )
      }
      this.isSavingResaleAgreement = false
    },
    deleteImage(){
      this.file = null
    },
    deleteImageLocal(){
      this.remodelationAgreement.remodelationReportName = null
      this.remodelationAgreement.remodelationReport = null
      this.deleteRemodelationReport = true
    },
  }
}
</script>

<style lang="scss">
	@import "@core/scss/vue/libs/vue-flatpicker.scss";

  .flatpickr-color-remodelation{
    background-color: white !important;
  }
  .flatpickr-color-remodelation:disabled{
    background-color: #efefef !important;
  }
  .image-delete-button{
    padding: 0.4rem 0.6rem;
    font-size: smaller;
  }
  .file-name-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    word-break: break-all;
    font-size: smaller;
  }
</style>