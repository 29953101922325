<template>
  <b-row>
    <b-col cols="12">
      <h4>Convenios de remodelación</h4>
    </b-col>
    <b-col align-self="end">
      <span class="font-weight-bolder">Primera remodelación:</span> {{ selectedContract.remodelationYear || 'Sin remodelación' }}
    </b-col>
    <b-col v-if="!disableEdition && can('fivesclub_contracts_show_contracts_action_button_remodelation_add_button')">
      <b-button
        type="button"
        class="float-right"
        variant="primary"
        @click="addNewRemodelation"
      > <feather-icon icon="PlusIcon" size="12" />
      </b-button>
    </b-col>

    <b-col cols="12" class="mt-1">
      <b-table
        v-if="remodelations.length > 0"
        :items="remodelations"
        :fields="fields"
        responsive
        small
        sticky-header="400px"
        sort-by="signDate"
        sort-desc
        class="remodelation-table-font-size"
        :busy.sync="isDeletingRemodelationAgreement"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner> <br>
            <strong>Cargando registros de convenios de remodelacion</strong>
          </div>
        </template>

        <template #head()="data">
          <div class="text-center">{{data.label}}</div>
        </template>

        <template #cell(remodelationReport)="row">
          <b-button
            v-if="!!row.item.remodelationReport"
            :href="imgUrl + row.item.remodelationReport"
            target="_blank"
            rel="noopener noreferrer"
            v-b-tooltip.hover.left
            size="sm"
            variant="link"
            :title="row.item.remodelationReportName"
          >
            <feather-icon icon="FileTextIcon" size="1.5x" />
          </b-button>
          <span v-else>--</span>
        </template>

        <template #cell(actions)="row">
          <div class="d-flex justify-content-center">
            <b-button variant="primary" class="mr-1" @click.prevent="editRemodelation(row.item.id)" size="sm" v-if="!disableEdition && can('fivesclub_contracts_show_contracts_remodelations_show_edit_button')">
              <feather-icon icon="Edit2Icon" size="12" />
            </b-button>

            <b-button variant="danger" @click.prevent="deleteRemodelation(row.item.id)" size="sm" v-if="!disableEdition && can('fivesclub_contracts_show_contracts_action_button_remodelation_delete_button')">
              <feather-icon icon="TrashIcon" size="12" />
            </b-button>
          </div>
        </template>
      </b-table>

      <div v-else-if="isLoadingRemodelationAgreements" class="text-center">
        <b-spinner /> <br> Cargando convenios de remodelacion
      </div>

      <div v-else>
        <b-alert show variant="warning" class="p-1 text-center">No hay convenios de remodelacion añadidos.</b-alert>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { formatDateSpecific, toCurrency } from "@/helpers/helpers"
import { utils } from "@/modules/fivesClub/mixins/utils"
import { acl } from "@/modules/auth/mixins/acl"
import { can } from '@/directives/acl'

export default {
  directives: { can },
  mixins: [utils, acl],
  props: {
    remodelations: {
      type: Array,
      required: true,
      default: []
    },
    isLoadingRemodelationAgreements: {
      type: Boolean,
      required: true
    },
    disableEdition: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,

      fields: [
        { key:'agreementDate', label: 'Fecha de convenio', class: 'text-center custom-padding-remodelations font-weight-bolder', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        }},
        { key:'agreementTypeName', label: 'Tipo de convenio', class: 'text-center custom-padding-remodelations', formatter: value => {
          return value || '--'
        }},
        { key:'year', label: 'año de compromiso', class: 'text-center custom-padding-remodelations', formatter: value => {
          return value || '--'
        }},

        { key:'remodelated', label: 'Remodelación realizada', class: 'text-center custom-padding-remodelations', formatter: value => {
          return value ? 'Si' : 'No'
        }},

        { key:'remodelationDate', label: 'Fecha de remodelación', class: 'text-center custom-padding-remodelations font-weight-bolder', formatter: value => {
          return value ? this.formatThisDate(value) : '--'
        }},
        { key:'amountBudget', label: 'Costo de remodelación', class: 'text-right custom-padding-remodelations font-weight-bolder', formatter: value => {
          return value ? this.formatCurrency(value) : '--'
        }},

        { key:'remodelationReport', label: 'Convenio', class: 'text-center custom-padding-remodelations' },
        { key:'actions', label: 'Acciones', class: 'text-center custom-padding-remodelations' },
      ],
      isDeletingRemodelationAgreement: false
    }
  },
  computed: {
    ...mapState("fivesClubContracts", ["selectedContract"])
  },
  methods: {
    ...mapActions("fivesClubContracts", ["deleteRemodelationPerContract"]),
    addNewRemodelation(){
      this.$emit('add-new-remodelation')
    },
    editRemodelation(id){
      this.$emit('edit-remodelation', id)
    },
    formatCurrency(amount){
      return toCurrency(parseFloat(amount))
    },
    formatThisDate(date){
      return formatDateSpecific({date, toFormat: 'DD/MM/YYYY'})
    },
    async deleteRemodelation(id){
      const payloadAction = { title: '¿Está seguro de borrar este registro?', text: 'Esta acción no se puede deshacer', showDenyButton: true, confirmButtonText: 'Sí, borrar', denyButtonText: 'Cancelar'}
      const { isConfirmed } = await this.$swal.fire(payloadAction)
      if( isConfirmed ){
        this.isDeletingRemodelationAgreement = true
        const response = await this.deleteRemodelationPerContract({id})
        if (response) this.$emit('reload-remodelations')
        this.isDeletingRemodelationAgreement = false
      }
    }
  }
}
</script>

<style>
.remodelation-table-font-size{
  font-size: 0.9rem;
}
.custom-padding-remodelations{
  padding: 0.6rem !important;
}
</style>